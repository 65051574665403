<template>
<aheader />
<section class="section">
  <div class="title mt-4">
    プライバシーポリシー
  </div>
  <div class="columns">
    <div class="column  content is-10 is-offset-1 has-text-left">
      <p class="seciton">
        株式会社おぐら屋（以下「当社」といいます。）は、当社の運営するウェブサイト(<a href="https://www.ogurayafuton.com">https://www.ogurayafuton.com</a>)」（以下「当サイト」といいます。）および当サイトにおいて当社が提供する各種サービス（以下「本サービス」といいます。）における、ユーザーについての個人情報（個人情報の保護に関する法律に定める「個人情報」を意味します。以下同様です。）を含む利用者情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定めます。
      </p>
      <section class="section">
        <p class="title is-4">
          1. 収集する利用者情報および収集方法
        </p>
        <hr>
        <p>
          本ポリシーにおいて、「利用者情報」とは、ユーザーの識別に係る情報、通信サービス上の行動履歴、その他ユーザーまたはユーザーの端末に関連して生成または蓄積された情報であって、本ポリシーに基づき当社が収集するものを意味するものとします。本サービスにおいて当社が収集する利用者情報は、その収集方法に応じて、以下のような情報があります。
        </p>
        <ol>
          <li>
            <p>ユーザーからご提供いただく情報
              <br>本サービスを利用するために、または本サービスの利用を通じてユーザーからご提供いただく情報は以下のとおりです。
            </p>
            <ul>
              <li>
                氏名、生年月日、性別等プロフィールに関する情報
              </li>
              <li>
                メールアドレス、電話番号、住所等連絡先に関する情報
              </li>
              <li>
                入力フォームその他当社が定める方法を通じてユーザーが入力または送信する情報
              </li>
            </ul>
          </li>
          <li>
            <p>
              ユーザーが本サービスを利用するにあたって、当社が収集する情報
              <br>当社は、ユーザーによる本サービスへのアクセス状況やそのご利用方法に関する情報を収集することがあります。これには以下の情報が含まれます。
            </p>
            <ul>
              <li>
                リファラ
              </li>
              <li>
                IPアドレス
              </li>
              <li>
                サーバーアクセスログに関する情報
              </li>
              <li>
                Cookie（クッキー）、広告識別子
              </li>
              <li>
                お使いの情報端末のOS、ブラウザ、言語設定、画面解像度などの情報
              </li>
            </ul>
          </li>
        </ol>
      </section>
      <section class="section">
        <p class="title is-4">
          2. 利用目的
        </p>
        <hr>
        <p>利用者情報の具体的な利用目的は、以下のとおりです。</p>
        <ol>
          <li>
            本サービスに関する登録の受付、本人確認、ユーザー認証、ユーザー設定の記録、利用料金の決済計算等、本サービスの提供、維持および保護のため
          </li>
          <li>
            ユーザーのトラフィック測定および行動測定のため
          </li>
          <li>
            当社の提携先企業が、取得したユーザーに関する情報と、ユーザーの個人情報とを紐づけた上で、ユーザーの趣味・嗜好を分析し、当社または当社の提携する第三者の商品等に関するマーケティング（ターゲティング広告の配信、表示等）およびその効果測定に用いるため
          </li>
          <li>
            本サービスに関するご案内、お問い合わせ等への対応のため
          </li>
          <li>
            懸賞、キャンペーンの実施のため
          </li>
          <li>
            マーケティング目的での調査、統計、分析のため
          </li>
          <li>
            当社もしくは当社の提携する第三者のサービスの向上、または、新サービスもしくは新機能の開発のため
          </li>
          <li>
            当社の提携する第三者に提供するため
          </li>
          <li>
            システムの維持、不具合対応のため
          </li>
          <li>
            本サービスに関する当社の規約、当サイトのご利用ガイド、ポリシー等（以下「規約等」といいます。）に違反する行為に対する対応のため
          </li>
          <li>
            本サービスに関する規約等の変更などを通知するため
          </li>
          <li>
            その他、前各号に付随する目的のため
          </li>
        </ol>
      </section>
      <section class="section">
        <p class="title is-4">3. 第三者提供</p>
        <hr>
        <p>当社は、利用者情報のうち、個人情報については、あらかじめユーザーの同意を得ないで、第三者（日本国外にある者を含みます。）に提供しません。但し、次に掲げる必要があり第三者（日本国外にある者を含みます。）に提供する場合はこの限りではありません。</p>
        <ol>
          <li>
            当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
          </li>
          <li>
            合併その他の事由による事業の承継に伴って個人情報が提供される場合
          </li>
          <li>
            第7項の定めに従って、情報収集モジュールの提供者へ個人情報が提供される場合
          </li>
          <li>
            国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、ユーザーの同意を得ることによって当該事務の遂行に支障を及ぼすおそれがある場合
          </li>
          <li>
            その他、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）その他の法令で認められる場合
          </li>
        </ol>
      </section>
      <section class="section">
        <p class="title is-4">
          4. 共同利用
        </p>
        <hr>
        <p>当社は、以下のとおりユーザーの個人情報を共同利用します。</p>
        <ol>
          <li>
            共同して利用される個人情報の項目
            <br>氏名、生年月日、年齢、性別、住所、電話番号、メールアドレス、商品・サービスの購買履歴
          </li>
          <li>
            共同して利用する者の範囲
            <br>当社と提携する店舗（以下単に「店舗」といいます。）
          </li>
          <li>
            共同して利用する者の利用目的
            <ul>
              <li>
                ユーザーが購入した商品の発送・店舗での引渡し
              </li>
              <li>
                ユーザーが当社に依頼した不用品の回収
              </li>
              <li>
                ユーザーからのお問い合わせへの対応
              </li>
              <li>
                当社主催の催事等において販売・提供される当社の商品・サービスに関するご案内(DM・チラシの発送を含みます。)
              </li>
            </ul>
          </li>
          <li>
            個人情報の管理について責任を有する者の氏名または名称
            <br>大阪市旭区森小路2丁目9番2号 株式会社おぐら屋 義平稔
          </li>
        </ol>
      </section>
      <section class="section">
        <p class="title is-4">5. Cookieの利用</p>
        <hr>
        <p>
          5-1. 当サイトでは、以下に掲げる目的のために、一部のコンテンツでCookieを利用するものとします。当サイトにおけるCookieの機能とは、ユーザーが当サイトをご覧になったという情報を、そのユーザーのコンピュータ内に記憶させておく機能のことです。
        </p>
        <ol>
          <li>
            当サイトに複数のページにまたがるアプリケーションがある場合に、より便利に当サイトをご覧いただくために必要な情報を一時的に保管する目的
          </li>
          <li>
            ユーザーが認証サービスにログインされているとき、保存されているユーザーの登録情報を参照して、ユーザーごとにカスタマイズされたサービスを提供できるようにする目的
          </li>
          <li>
            当サイトを通じてより良いサービスを提供していくために、当社または当社が調査分析業務を委託する会社が、当サイト上のユーザーのアクセス傾向を分析する目的
          </li>
          <li>
            ユーザーが興味を持っている内容や、当サイト上での利用状況をもとに、最も適切な広告を他社ウェブサイト上で表示する目的
          </li>
          <li>
            セキュリティ保持のため、ご利用から一定の時間が経過したユーザーに対してパスワードの再入力（再認証）を促す目的
          </li>
        </ol>
        <p>
          5-2. ユーザーが本サービスを適切に利用するためには、前項を承諾し、Cookieを受け付けることが条件となります。ユーザーはブラウザでCookieを拒否するための設定を行うこともできますが、その場合、一部が適切に機能しなくなったり、使えなくなったりするコンテンツが含まれることを予め承諾するものとします。
        </p>
      </section>
      <section class="section">
        <p class="title is-4">6. SSLの使用</p>
        <hr>
        <p>個人情報の入力時には、セキュリティ確保のため、これらの情報が傍受、妨害または改ざんされることを防ぐ目的でSSL（Secure Sockets Layer）※技術を使用しております。</p>
        <p>※ SSLは情報を暗号化することで、盗聴防止やデータの改ざん防止送受信する機能のことです。SSLを利用することでより安全に情報を送信する事が可能となります。</p>
      </section>
      <section class="section">
        <p class="title is-4">7. 第三者が提供する情報収集モジュールの利用</p>
        <hr>
        <p>
          7-1. 当社は、サービスの利用状況の計測・分析・改良のため、ユーザーの利用状況に応じた広告表示をするため等の理由で、第三者の提供する情報収集モジュールを利用します。
        </p>
        <p>
          7-2. 情報収集モジュールは、ユーザーの利用状況や広告表示に関する情報を自動的に収集し、情報収集モジュールの提供者（以下「提供者」といいます）に送信します。
        </p>
        <p>
          7-3. 提供者に送信された情報は、各提供者の定めるプライバシーポリシーその他の規定に基づき管理されます。なお、当該情報の利用停止を希望される場合は、提供者のウェブサイトからオプトアウトの設定をしてください。提供者のプライバシーポリシー、オプトアウトの設定方法については、オンラインプライバシー通知のリンクにてご確認ください。
        </p>
      </section>
      <section class="section">
        <p class="title is-4">8. 個人関連情報の取扱い</p>
        <hr>
        <p>
          当社は、利用者情報のうち個人関連情報（利用者情報のうち他の情報と容易に照合できることなどにより特定の個人を識別することができる情報以外の情報をいいます。）を、提携事業者（DNP事業者その他の広告会社、調査・分析会社等を含みますが、これらに限りません。）から取得し、ユーザーの個人情報と突合させた上で、「2. 利用目的」記載の目的で利用する場合がございます。
        </p>
      </section>
      <section class="section">
        <p class="title is-4">
          9. 個人情報等の開示
        </p>
        <hr>
        <p>
          当社は、ユーザーから、個人情報保護法の定めに基づき個人情報または第三者提供記録の開示を求められたときは、ユーザーご本人からのご請求であることを確認の上で、ユーザーに対し、遅滞なく開示を行います（当該個人情報または第三者提供記録が存在しないときにはその旨を通知いたします。）。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示につきましては、当社所定の手数料を頂戴しておりますので、あらかじめ御了承ください。
        </p>
      </section>
      <section class="section">
        <p class="title is-4">
          10. 個人情報の訂正および利用停止等
        </p>
        <hr>
        <p>
          10-1. 当社は、ユーザーから、(1)個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、および(2)あらかじめ公表された利用目的の範囲を超えて取扱われているという理由、不適正な利用が行われているという理由または偽りその他不正の手段により収集されたものであるという理由により、個人情報保護法の定めに基づきその利用の停止を求められた場合には、ユーザーご本人からのご請求であることを確認の上で遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正または利用停止を行い、その旨をユーザーに通知します。なお、訂正または利用停止を行わない旨の決定をしたときは、ユーザーに対しその旨を通知いたします。
        </p>
        <p>
          10-2. 当社は、ユーザーから、ユーザーの個人情報について消去を求められた場合、当社が当該請求に応じる必要があると判断した場合は、ユーザーご本人からのご請求であることを確認の上で、個人情報の消去を行い、その旨をユーザーに通知します。
        </p>
        <p>
          10-3. 個人情報保護法その他の法令により、当社が個人情報の訂正等または利用停止等の義務を負わない場合は、9-1および9-2の規定は適用されません。
        </p>
      </section>
    </div>
  </div>
</section>
<afooter />
</template>
<script>
export default {
  mounted() {
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    }
  }
}
</script>
<style scoped>
section {
  padding: 2rem 1rem;
}
</style>
